<script setup>
const store = useProjectObjectClassificationStore()
const authStore = useAuthStore()
const objectClassificationCategoryStore = useObjectClassificationCategoryStore()

const {items} = storeToRefs(store)
const { items: objectClassificationCategories } = storeToRefs(objectClassificationCategoryStore)

const headers = [
  {title: 'Category', align: 'start', key: 'classification.category'},
  {title: 'Name', align: 'start', key: 'classification.name'},
  {title: 'Actions', key: 'actions', align: 'end', sortable: false},
]
const sortedCategories = computed(() => {
  return [...objectClassificationCategories.value].sort((a, b) => a.order - b.order)
})

// v-data-table doesn't support sorting the groups, use categoryPosition as a workaround
const itemsDisplay = computed(() => {
  return items.value.map(item => {
    const categoryPosition = sortedCategories.value.findIndex(category => category._id === item.classification.category)
    return { ...item, categoryPosition }
  })
})
</script>

<template>
  <v-data-table
      items-per-page="10"
      :group-by="[{ key: 'categoryPosition', order: 'asc' }]"
      :sort-by="[{ key: 'classification.order', order: 'asc' }]"
      :headers="headers"
      :items="itemsDisplay"
      density="compact"
  >
    <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
      <tr>
        <td class="ps-0 pe-0" :colspan="columns.length">
          <v-btn
              size="small"
              variant="text"
              :icon="isGroupOpen(item) ? '$expand' : '$next'"
              @click="toggleGroup(item)"
          ></v-btn>
          {{ sortedCategories.find((category, index) => category && index === item.value)?.name || item.value }}
        </td>
      </tr>
    </template>
    <template v-slot:item.classification.category="{ item }">
      <span v-if="item.classification">{{ objectClassificationCategories.find(category => category._id === item.classification.category)?.name || item.classification.category }}</span>
      <span v-else>Removed classification</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          v-if="authStore.hasObjectPermission(item, ['remove_all', 'remove_assigned'])"
          @click="store.toggleDeleteDialog(item)"
          size="small"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script setup>
import DeleteDialog from "@/components/dialog/DeleteDialog.vue";
import ProjectMemberDialog from "@/components/projects/ProjectMemberDialog.vue";

const route = useRoute()
const { api } = useFeathers()

const snackbarStore = useSnackbarStore()
const authStore = useAuthStore()
const projectMemberStore = useProjectMembersStore()
const projectStore = useProjectStore()

const authData = ref(null)

const props = defineProps({
  itemsPerPage: {type: Number, default: 10}
})

const { 'currentItem': project } = storeToRefs(projectStore)
const { items, deleteItem } = storeToRefs(projectMemberStore)

// Ref to track searching in user data table
const search = ref(null)
const headers = [
  { title: 'First name', align: 'start', key: 'firstName' },
  { title: 'Last name', align: 'start', key: 'lastName' },
  { title: 'Role', align: 'start', key: 'role' },
  { title: 'E-mail', align: 'start', key: 'user.email' },
  { title: 'Actions', key: 'actions', align: 'end', sortable: false },
]

function onProjectMemberSaved () {
  snackbarStore.setMessage('Project member was saved successfully', 'success', 5000)
}

function onProjectMemberDeleted (){
  snackbarStore.setMessage('Project member was removed successfully', 'success', 5000)
}

onMounted(async () => {
  authData.value = await authStore.getPromise()
})

watch(() => project.value, async (newValue) => {
  if(newValue._id){
    await projectMemberStore.fetchItems({
      project: project.value._id
    })
  }
})
</script>

<template>
  <h1 class="font-weight-thin">
    Members

    <v-btn
      v-if="authStore.hasServicePermission('project-members', ['create_all', 'create_assigned'])"
      @click="projectMemberStore.toggleCreateUpdateDialog({
        project: project._id,
      })"
      class="ml-3 mb-1"
      append-icon="mdi-plus"
      color="primary"
      size="small"
      variant="outlined">
      Add
    </v-btn>
  </h1>

  <v-text-field
      v-model="search"
      prepend-inner-icon="mdi-magnify"
      placeholder="Search project members"
      label="Search project members"
      single-line
      hide-details
      class="mb-5"
  ></v-text-field>

  <v-data-table
      items-per-page="10"
      :headers="headers"
      :items="items"
      item-value="email"
      density="compact"
      :search="search"
  >
    <template v-slot:item.firstName="{ item }">
      <span v-if="item.user.firstName">{{ item.user.firstName }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.lastName="{ item }">
      <span v-if="item.user.lastName">{{ item.user.lastName }}</span>
      <span v-else>-</span>
    </template>
    <template v-slot:item.role="{ item }">
      {{ item.role.name }}
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
          v-if="authData && item._id !== authData.user._id && authStore.hasObjectPermission(item, ['patch_all', 'patch_assigned'])"
          size="small"
          @click="projectMemberStore.toggleCreateUpdateDialog({
            _id: item._id,
            project: item.project._id,
            user: item.user._id,
            role: item.role._id,
          })"
      >
        mdi-pencil
      </v-icon>
      
      <v-icon
          v-if="authData && item._id !== authData.user._id && authStore.hasObjectPermission(item, ['patch_all', 'patch_assigned'])"
          size="small"
          @click="projectMemberStore.toggleDeleteDialog(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>

  <project-member-dialog @saved="onProjectMemberSaved"></project-member-dialog>
  <delete-dialog :store="projectMemberStore" :name="deleteItem?.user?.fullName" @deleted="onProjectMemberDeleted"></delete-dialog>
</template>
// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { VDataTable } from "vuetify/components"

// Vuetify
import { createVuetify } from 'vuetify'

const rhdhvLight = {
    dark: false,
    colors: {
        background: '#FFFFFF',
        surface: '#FFFFFF',
        primary: '#0186A7',
        secondary: '#0186A7'
    }
}

export default createVuetify({
    components: {
        VDataTable,
    },
    theme: {
        defaultTheme: 'rhdhvLight',
        themes: {
            rhdhvLight,
        }
    },
    // icons: {
    //     iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    // },
})

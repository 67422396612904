<script setup>
import ClassificationSelect from "@/components/projects/ClassificationSelect.vue";
import {getSafeFileName} from "@/utils/fileUtils.js";

const emit = defineEmits(['update:modelValue'])

const isValid = computed(() => {
  return !!objectImportFormData.value.objectClassification && !!objectImportFormData.value.file
})

const {api} = useFeathers()
const projectStore = useProjectStore()
const objectClassificationStore = useObjectClassificationStore()
const projectObjectClassificationStore = useProjectObjectClassificationStore()
const objectStore = useObjectStore()
const snackbarStore = useSnackbarStore()

const { items: projectClassificationItems} = storeToRefs(projectObjectClassificationStore)
const { items: objectClassifications } = storeToRefs(objectClassificationStore)
const { currentItem: currentProject } = storeToRefs(projectStore)
const { importDialog, importLoading } = storeToRefs(objectStore)

const objectImportFormData = ref({
  objectClassification: null,
})

function onObjectsImported() {
  snackbarStore.setMessage('Objects imported successfully.', 'success', 5000)
}


function setClassification(value) {
  objectImportFormData.value.objectClassification = value
}

const classificationObjects = computed(() => objectClassifications.value?.filter(item =>
    projectClassificationItems.value?.findIndex(projectItem => projectItem.classification?._id === item._id) >= 0)
);

async function downloadSampleFile() {
  await fetch(import.meta.env.VITE_APP_API_BASE_URL + '/objects', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Service-Method': 'download',
      'Authorization': 'Bearer ' + (await api.authentication.getAccessToken())
    },
    body: JSON.stringify({
      'project': currentProject.value._id,
      'objectClassification': objectImportFormData.value.objectClassification
    })
  }).then(res => res.blob())
    .then(blob => {
      const selectedObjectClassificaiton = classificationObjects.value.find(item => item._id === objectImportFormData.value.objectClassification)
      const fileName = getSafeFileName(`template_${selectedObjectClassificaiton.name}`) + '.xlsx'
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
}

async function importObjects() {
  const validationResult = await formElement.value.validate()
  if (!validationResult.valid) {
    return false
  }

  let data = new FormData()
  data.append('project', currentProject.value._id)
  data.append('objectClassification', objectImportFormData.value.objectClassification)
  data.append('file', objectImportFormData.value.file)

  try {
    objectStore.toggleImportLoading(true)
    const response = await fetch(import.meta.env.VITE_APP_API_BASE_URL + '/objects', {
      method: 'POST',
      body: data,
      headers: {
        'X-Service-Method': 'import',
        'Authorization': 'Bearer ' + (await api.authentication.getAccessToken()),
      },
    })
    if (response.status === 400) {
      const errorContent = await response.json()
      const detailMessages = errorContent.errors.map(({ fieldName, message }) => {
        return `\n  ${fieldName}: ${message}`
      })
      snackbarStore.setMessage(errorContent.message.concat(...detailMessages), 'error', 15000)
      return
    }

    if (!response.ok) {
      snackbarStore.setMessage('Network response was not OK', 'error', 5000)
      return
    }

    const createdItems = await response.json()
    objectStore.addItems(createdItems)
    onObjectsImported()
  } catch (e) {
    snackbarStore.setMessage('Could not import the objects! Check your file or try again later', 'error', 5000)
    console.log(e)
  } finally {
    objectStore.toggleImportLoading(false)
  }
}

const formElement = ref(null)
watch(() => formElement.value, (newFormElementValue) => {
  if(newFormElementValue){
    newFormElementValue.validate();
  }
})
</script>

<template>
  <v-dialog
      v-model="importDialog"
      max-width="1500px"
  >
    <v-card class="pa-3">
      <v-card-title>
        <span>Import objects</span>
      </v-card-title>

      <v-card-text class="pa-0">
        <v-form ref="formElement" @submit.prevent="importObjects" :disabled="importLoading">
          <v-container>
            <v-row>
              <v-col
                  cols="12"
              >
                <classification-select
                    :items="classificationObjects"
                    :multiple="false"
                    :selected="objectImportFormData.objectClassification"
                    @update="setClassification($event)"
                ></classification-select>
              </v-col>
              <v-col
                  cols="12"
              >
                <v-file-input
                    v-model="objectImportFormData.file"
                    accept=".xlsx"
                    label="File containing objects"
                    outlined
                    prepend-icon="mdi-file"></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn
            @click="downloadSampleFile"
            :disabled="!objectImportFormData.objectClassification"
            :loading="1===2"
            color="primary"
            variant="text"
        >
          Download sample file
        </v-btn>

        <v-btn
            @click="importObjects"
            :disabled="isValid === false"
            :loading="importLoading"
            color="primary"
            variant="text"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

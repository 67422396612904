<script setup>
const snackbarStore = useSnackbarStore()
const projectTokenStore = useProjectTokenStore()

const {items} = storeToRefs(projectTokenStore)

const {api} = useFeathers()
const authStore = useAuthStore()

const headers = [
  {title: 'Name', align: 'start', sortable: false, key: 'name',},
  {title: 'Token', align: 'start', key: 'tokenReadable'},
  {title: 'Date added', align: 'start', key: 'createdAt'},
  {title: 'Actions', align: 'end', key: 'actions', sortable: false},
]
</script>

<template>
  <v-data-table
      items-per-page="10"
      :headers="headers"
      :items="items"
      item-value="email"
      density="compact"
  >
    <template v-slot:item.actions="{ item }">
      <v-icon
          size="small"
          v-if="authStore.hasObjectPermission(item, ['patch_all', 'patch_assigned'])"
          @click="projectTokenStore.toggleCreateUpdateDialog(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          class="ms-1"
          size="small"
          v-if="authStore.hasObjectPermission(item, ['remove_all', 'remove_assigned'])"
          @click="projectTokenStore.toggleDeleteDialog(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script setup>
const emit = defineEmits(['moveUp', 'moveDown'])
const props = defineProps({
  color: { type: String, default: 'primary' },
  size: { default: 'default' },
  disabledUp: { type: Boolean, default: false },
  disabledDown: { type: Boolean, default: false },
})
</script>

<template>
  <div>
    <v-icon
      :class="{ disabled: disabledUp }"
      :color="props.color"
      :size="props.size"
      icon="mdi-chevron-up"
      color="primary"
      @click.stop="emit('moveUp')"
    />
    <v-icon
      :class="{ disabled: disabledDown }"
      :color="props.color"
      :size="props.size"
      icon="mdi-chevron-down"
      color="primary"
      @click.stop="emit('moveDown')"
    />
  </div>
</template>

<style scoped>
.disabled {
  visibility: hidden;
}
</style>
